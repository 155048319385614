import productsService from '@/services/products'

const state = {
  list: []
}

const mutations = {
  addItem (state, product) {
    state.list = state.list.filter(p => p.ProductID !== product.ProductID)
    state.list.push(product)
  }
}

const actions = {
  readAllProducts ({commit}, {token}) {
    return new Promise((resolve, reject) => {
      productsService.readAllProducts(token)
      .then(products => {
        products.forEach(product => commit('addItem', product))
        resolve(token)
      })
      .catch(() => {
        // TODO : Dispatch alert
        resolve(token)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

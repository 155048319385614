Array.prototype.first = function() {
  for (var i in this) return this[i]
}

Array.prototype.last = function() {
  for (var i in this.reverse()) return this[i]
}

Array.prototype.flattenDeep = function() {
  return flattenDeep(this)
}

Array.prototype.findIndex = function(method) {
  for (var i = 0; i < this.length; i++) {
    if (method(this[i])) return i
  }

  return -1
}

function flattenDeep(arr) {
  return arr.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val)), [])
}

const state = {
  isVisible: false,
  isError403: false,
  isError500: false
}

const mutations = {
  isVisible (state, isVisible) {
    state.isVisible = isVisible
  },
  isError403 (state, isError403) {
    state.isError403 = isError403
  },
  isError500 (state, isError500) {
    state.isError500 = isError500
  }
}

const actions = {
  updateVisibility ({state, commit}, isVisible) {
    return new Promise((resolve, reject) => {
      commit('isVisible', isVisible)
      document.getElementsByClassName('app-loading')[0].style.display = state.isVisible ? 'none' : 'block';
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

import { Consts } from '@/helpers/consts'

let mapEn = new Map()

mapEn.set(Consts.URL_HOME, '')
mapEn.set(Consts.URL_TICKET_LIST, 'tickets')
mapEn.set(Consts.URL_TICKET_DETAIL, ':id')
mapEn.set(Consts.URL_TICKET_ADD, 'add')
mapEn.set(Consts.URL_TICKET_CONFIRM, 'confirm/:id')

const getUrl = (key, locale) => {
  if (locale === Consts.LANG_EN) {
    return mapEn.get(key)
  }
}

export default {
  en: mapEn,
  getUrl
}

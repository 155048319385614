<template>
  <error-layout>
    <template v-slot:header>
      <h1 class="text-h6 text-secondary">
        {{ $t('error-500-title') }}
      </h1>
    </template>
    <template v-slot:default>
      <h2 class="headline mb-3">
        {{ $t('error-500-subtitle') }}
      </h2>
      <p v-html="$t('error-500-text')" class="ma-0"></p>
    </template>
  </error-layout>
</template>

<script>
import ErrorLayout from './layout'
import {defineComponent} from "vue";

export default defineComponent({
  components: {
    ErrorLayout,
  }
})
</script>

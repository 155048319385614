import "@/extends/array";
import { createApp, h } from "vue";
import App from "@/app.vue";
import i18n from "@/helpers/i18n";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import router from "@/helpers/router";
import { useI18n } from "vue-i18n";
import { createAuth0 } from "@auth0/auth0-vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { Consts } from "@/helpers/consts";

const app = createApp({
  setup() {
    const { t } = useI18n();
    return { t };
  },
  render: () => h(App),
});

app.use(
  createAuth0({
    domain: Consts.AUTH0_DOMAIN,
    clientId: Consts.AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      scope:
        "openid profile email",
    },
  })
);

app.component("EasyDataTable", Vue3EasyDataTable);
app.component("VueDatePicker", VueDatePicker);
app.use(i18n).use(store).use(router.instance).use(vuetify);

app.mount("#app");

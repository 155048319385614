<template>
  <v-chip size="small" :color="color" text-color="white" class="ma-0">
    {{ $t(`status-code-${code}`) }}
  </v-chip>
</template>

<script>
import {computed, defineComponent} from "vue";
import {VChip} from "vuetify/components";

export default defineComponent({
  components: {
    VChip
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const color = computed(() => {
      switch (props.code) {
          // Closed
        case '6':
          return '#20af6b'
          // Green
        case '1':
        case '5':
        case 'Y1':
        case 'Y3':
        case 'Y4':
        case 'Z0':
        case 'Z9':
        case 'ZC':
        case 'ZD':
        case 'ZE':
        case 'ZG':
        case 'ZT':
        case 'ZZ':
          return '#7dc242'
          // Yellow
        case '4':
        case '7':
        case 'Z5':
        case 'Z6':
        case 'Z7':
        case 'ZF':
          return '#fba500'
          // Red
        case 'Y2':
        case 'ZH':
          return '#f24533'
          // Blue (Default)
        default:
          return '#0069aa'
      }
    })

    return {
      color
    }
  }
})
</script>

<template>
  <div>
    <!-- Heading -->
    <v-container fluid>
      <v-row align-content="center" justify="space-between">
        <v-col>
          <h1 class="text-h3 text-secondary">
            {{ $t("ticket-list-title") }}
          </h1>
        </v-col>
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-btn
            block
            color="primary"
            class="ma-0"
            :to="getLocalizedUrl(consts('URL_TICKET_ADD'))"
          >
            <v-icon left icon="add_circle_outline"></v-icon>
            {{ $t("ticket-list-new-ticket") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid :class="{ 'pt-0': true, 'pb-0': xs, 'px-0': xs }">
      <!-- Data table -->
      <div class="elevation-1">
        <v-progress-linear
          v-show="isFetching"
          indeterminate
          height="2"
          class="ticket-detail-progress"
        />
        <v-container fluid>
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <!-- Search -->
              <v-text-field
                v-model="filterSearch"
                hide-details
                clearable
                prepend-inner-icon="search"
                :placeholder="$t('ticket-list-search-placeholder')"
                class="ma-0 pa-0"
              ></v-text-field>
            </div>
            <div class="flex-shrink-1">
              <!-- Checkbox -->
              <v-checkbox
                v-model="filterOpenOnly"
                hide-details
                color="primary"
                :label="$t('ticket-list-open-label')"
                class="ma-0 pa-0"
              ></v-checkbox>
            </div>
            <div class="flex-shrink-1">
              <!-- Filter button -->
              <v-btn
                color="primary"
                variant="plain"
                class="ml-4 px-1"
                @click="filterDrawerIsOpen = !filterDrawerIsOpen"
              >
                <v-icon left>filter_list</v-icon>
                {{ $t("ticket-list-filters-label") }}
                <span v-if="filterCount > 0">&nbsp;({{ filterCount }})</span>
              </v-btn>
            </div>
          </div>
        </v-container>
        <v-divider />
        <EasyDataTable
          alternating
          :headers="tableHeaders"
          :items="tableItems"
          @click-row="goToTicket"
          @update-sort="updateSort"
          :sort-by="sortBy"
          :sort-type="sortType"
        >
          <template #item-TicketID="{ TicketID }">
            {{ TicketID }}
          </template>
          <template #item-Subject="{ Subject }">
            {{ Subject }}
          </template>
          <template #item-PriorityCode="{ PriorityCode }">
            <chip-priority :code="PriorityCode" />
          </template>
          <template #item-StatusText="{ StatusCode }">
            <chip-status :code="StatusCode" />
          </template>
          <template #item-CreatedDate="{ CreatedDate }">
            {{ $d(new Date(CreatedDate), "datetime") }}
          </template>
          <template #item-LastChangeDate="{ LastChangeDate }">
            {{ $d(new Date(LastChangeDate), "datetime") }}
          </template>
          <template #item-Product="{ Product }">
            {{ Product }}
          </template>
          <template #item-SupportContact="{ SupportContact }">
            {{ SupportContact }}
          </template>
          <template #empty-message>
            {{ noDataText }}
          </template>
        </EasyDataTable>
      </div>
    </v-container>
    <!-- Filters drawer -->
    <v-navigation-drawer
      v-model="filterDrawerIsOpen"
      location="right"
      temporary
      width="280"
    >
      <!-- Heading -->
      <v-toolbar flat>
        <v-toolbar-title>
          <p class="ma-0 text-secondary">
            {{ $t("ticket-list-filters-title") }}
          </p>
        </v-toolbar-title>
      </v-toolbar>
      <!-- Clear filters -->
      <div :class="filterSectionClass">
        <v-btn
          :disabled="filterCount <= 0"
          @click="deleteDrawerFilters"
          variant="outlined"
          block
          color="primary"
          class="mb-0"
        >
          Clear filters
        </v-btn>
      </div>
      <v-divider />
      <!-- Creation -->
      <div :class="filterSectionClass">
        <p class="text-body-2 ma-0">
          {{ $t("prop-date-create") }}
        </p>
        <VueDatePicker
          v-model="filterDateCreateFrom"
          uid="filterDateCreateFrom"
          :max-date="filterDateCreateTo || new Date().toISOString()"
          :placeholder="$t('ticket-list-from-label')"
        />
        <VueDatePicker
          v-model="filterDateCreateTo"
          uid="filterDateCreateTo"
          :min-date="filterDateCreateFrom"
          :max-date="new Date().toISOString()"
          :placeholder="$t('ticket-list-to-label')"
        />
      </div>
      <v-divider />
      <!-- Update -->
      <div :class="filterSectionClass">
        <p class="text-body-2 ma-0">
          {{ $t("prop-date-update") }}
        </p>
        <VueDatePicker
          v-model="filterDateUpdateFrom"
          uid="filterDateUpdateFrom"
          :max-date="filterDateUpdateTo || new Date().toISOString()"
          :placeholder="$t('ticket-list-from-label')"
        />
        <VueDatePicker
          v-model="filterDateUpdateTo"
          uid="filterDateUpdateTo"
          :max-date="new Date().toISOString()"
          :min-date="filterDateUpdateFrom"
          :placeholder="$t('ticket-list-to-label')"
        />
      </div>
      <v-divider />
      <!-- Product -->
      <div :class="filterSectionClass">
        <p class="text-body-2 ma-0">
          {{ $t("prop-product") }}
        </p>
        <v-select
          v-model="filterProduct"
          :items="productItems"
          hide-details
          class="ma-0 pa-0"
          placeholder="All"
        ></v-select>
      </div>
      <v-divider />
      <!-- Priority -->
      <div :class="filterSectionClass">
        <p class="text-body-2 ma-0">
          {{ $t("prop-priority") }}
        </p>
        <v-select
          v-model="filterPriorityCode"
          :items="priorityItems"
          hide-details
          class="ma-0 pa-0"
          placeholder="All"
        ></v-select>
      </div>
      <v-divider />
      <!-- Status -->
      <div :class="filterSectionClass">
        <p class="text-body-2 ma-0">
          {{ $t("prop-status") }}
        </p>
        <v-select
          v-model="filterStatusCode"
          :items="statusItems"
          hide-details
          class="ma-0 pa-0"
          placeholder="All"
        ></v-select>
      </div>
      <v-divider />
      <!-- Support contact -->
      <div :class="filterSectionClass">
        <p class="text-body-2 ma-0">
          {{ $t("prop-support-contact") }}
        </p>
        <v-select
          v-model="filterSupportContact"
          :items="supportContactItems"
          hide-details
          class="ma-0 pa-0"
          placeholder="All"
        ></v-select>
      </div>
      <v-divider />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { Consts } from "@/helpers/consts";
import { useStore } from "vuex";
import ChipPriority from "@/components/chip/priority";
import ChipStatus from "@/components/chip/status";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import { useRouter } from "vue-router";
import useLocalizedUrl from "@/composables/localizedUrl";
import useConsts from "@/composables/consts";
import useAuth0Api from "@/composables/auth0";

export default defineComponent({
  components: {
    ChipPriority,
    ChipStatus,
  },

  setup() {
    const { t } = useI18n();
    const { mdAndUp, xs } = useDisplay();
    const store = useStore();
    const router = useRouter();
    const { getLocalizedUrl } = useLocalizedUrl();
    const { consts } = useConsts();
    const { getC4cValue, getTokenValue } = useAuth0Api();

    const tableRowsPerPages = [
      10,
      25,
      { text: t("ticket-list-rows-all"), value: -1 },
    ];
    const tableHeaders = [
      {
        text: t("prop-ticket-id"),
        value: "TicketID",
        sortable: true,
      },
      {
        text: t("prop-subject"),
        value: "Subject",
        sortable: true,
      },
      {
        text: t("prop-priority"),
        value: "PriorityCode",
        sortable: true,
      },
      {
        text: t("prop-status"),
        value: "StatusText",
        sortable: true,
      },
      {
        text: t("prop-date-create"),
        value: "CreatedDate",
        sortable: true,
      },
      {
        text: t("prop-date-update"),
        value: "LastChangeDate",
        sortable: true,
      },
      {
        text: t("prop-product"),
        value: "Product",
        sortable: true,
      },
      {
        text: t("prop-support-contact"),
        value: "SupportContact",
        sortable: true,
      },
    ];
    const isFetching = ref(false);
    const filterDrawerIsOpen = ref(false);

    const itemsSorting = (a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    };

    onMounted(() => {
      isFetching.value = true;
      getC4cValue().then((c4c) => {
        getTokenValue()
          .then((token) => readAllTickets(token, c4c))
          .then((token) => (isFetching.value = false))
          .catch(() => (isFetching.value = false));
      });
    });

    const tickets = computed(() => Consts.STATE_TICKETS_LIST(store.state));
    const filterSectionClass = computed(
      () => `pt-3 pb-4 ${mdAndUp ? "px-4" : "px-3"}`
    );
    const tableItems = computed(() => {
      let searchTerm = filterSearch.value
        ? filterSearch.value.toLowerCase().trim()
        : null;
      let openOnly = filterOpenOnly.value || null;
      let priorityCode =
        typeof filterPriorityCode.value === "string"
          ? filterPriorityCode.value
          : null;
      let statusCode =
        typeof filterStatusCode.value === "string"
          ? filterStatusCode.value
          : null;
      let dateCreateFrom = filterDateCreateFrom.value
        ? new Date(filterDateCreateFrom.value)
        : null;
      let dateCreateTo = filterDateCreateTo.value
        ? new Date(filterDateCreateTo.value)
        : null;
      let dateUpdateFrom = filterDateUpdateFrom.value
        ? new Date(filterDateUpdateFrom.value)
        : null;
      let dateUpdateTo = filterDateUpdateTo.value
        ? new Date(filterDateUpdateTo.value)
        : null;
      let product =
        typeof filterProduct.value === "string" ? filterProduct.value : null;
      let supportContact =
        typeof filterSupportContact.value === "string"
          ? filterSupportContact.value
          : null;
      return tickets.value.filter((t) => {
        let mismatch =
          !(
            !searchTerm ||
            t.Subject.toLowerCase().indexOf(searchTerm) !== -1 ||
            t.TicketID.indexOf(searchTerm) !== -1
          ) ||
          !(!openOnly || !Consts.STATUS_CODE_CLOSED.includes(t.StatusCode)) ||
          !(priorityCode === null || t.PriorityCode === priorityCode) ||
          !(statusCode === null || t.StatusCode === statusCode) ||
          !(product === null || t.Product === product) ||
          !(!dateCreateFrom || new Date(t.CreatedDate) >= dateCreateFrom) ||
          !(!dateCreateTo || new Date(t.CreatedDate) <= dateCreateTo) ||
          !(!dateUpdateFrom || new Date(t.LastChangeDate) >= dateUpdateFrom) ||
          !(!dateUpdateTo || new Date(t.LastChangeDate) <= dateUpdateTo) ||
          !(product === null || t.Product === product) ||
          !(supportContact === null || t.SupportContact === supportContact);
        return !mismatch;
      });
    });
    const productItems = computed(() => {
      let items = [];
      let product = [];
      tickets.value.forEach(
        (t) => product.includes(t.Product) || product.push(t.Product)
      );
      product.sort();
      product.forEach((x) =>
        items.push({
          title: x === "" ? t("ticket-list-none-label") : x,
          value: x,
        })
      );
      items.unshift({ title: t("ticket-list-all-label"), value: null });
      return items;
    });
    const priorityItems = computed(() => {
      let items = [];
      let code = [];
      tickets.value.forEach(
        (t) => code.includes(t.PriorityCode) || code.push(t.PriorityCode)
      );
      code.forEach((x) =>
        items.push({ title: t(`priority-code-${x}`), value: x })
      );
      items.sort(itemsSorting);
      items.unshift({ title: t("ticket-list-all-label"), value: null });
      return items;
    });
    const statusItems = computed(() => {
      let items = [];
      let code = [];
      tickets.value.forEach(
        (t) => code.includes(t.StatusCode) || code.push(t.StatusCode)
      );
      code.forEach((x) =>
        items.push({ title: t(`status-code-${x}`), value: x })
      );
      items.sort(itemsSorting);
      items.unshift({ title: t("ticket-list-all-label"), value: null });
      return items;
    });
    const supportContactItems = computed(() => {
      let items = [];
      let contact = [];
      tickets.value.forEach(
        (t) =>
          contact.includes(t.SupportContact) || contact.push(t.SupportContact)
      );
      contact.sort();
      contact.forEach((x) =>
        items.push({
          title: x === "" ? t("ticket-list-none-label") : x,
          value: x,
        })
      );
      items.unshift({ title: t("ticket-list-all-label"), value: null });
      return items;
    });
    const filterSearch = computed({
      get() {
        return Consts.STATE_FILTERS_SEARCH(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_SEARCH, value);
      },
    });
    const filterOpenOnly = computed({
      get() {
        return Consts.STATE_FILTERS_OPEN_ONLY(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_OPEN_ONLY, value);
      },
    });
    const filterDateCreateFrom = computed({
      get() {
        return Consts.STATE_FILTERS_DATE_CREATE_FROM(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_DATE_CREATE_FROM, value);
      },
    });
    const filterDateCreateTo = computed({
      get() {
        return Consts.STATE_FILTERS_DATE_CREATE_TO(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_DATE_CREATE_TO, value);
      },
    });
    const filterDateUpdateFrom = computed({
      get() {
        return Consts.STATE_FILTERS_DATE_UPDATE_FROM(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_DATE_UPDATE_FROM, value);
      },
    });
    const filterDateUpdateTo = computed({
      get() {
        return Consts.STATE_FILTERS_DATE_UPDATE_TO(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_DATE_UPDATE_TO, value);
      },
    });
    const filterProduct = computed({
      get() {
        return Consts.STATE_FILTERS_PRODUCT(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_PRODUCT, value);
      },
    });
    const filterPriorityCode = computed({
      get() {
        return Consts.STATE_FILTERS_PRIORITY_CODE(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_PRIORITY_CODE, value);
      },
    });
    const filterStatusCode = computed({
      get() {
        return Consts.STATE_FILTERS_STATUS_CODE(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_STATUS_CODE, value);
      },
    });
    const filterSupportContact = computed({
      get() {
        return Consts.STATE_FILTERS_SUPPORT_CONTACT(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_SUPPORT_CONTACT, value);
      },
    });
    const filterPagination = computed({
      get() {
        return Consts.STATE_FILTERS_PAGINATION(store.state);
      },
      set(value) {
        return store.commit(Consts.MUTATION_FILTERS_PAGINATION, value);
      },
    });

    const sortBy = computed(() => Consts.STATE_FILTERS_SORT_BY(store.state));
    const sortType = computed(() =>
      Consts.STATE_FILTERS_SORT_TYPE(store.state)
    );

    const updateSort = (sortOptions) => {
      store.commit(Consts.MUTATION_FILTERS_SORT_BY, sortOptions.sortBy);
      store.commit(Consts.MUTATION_FILTERS_SORT_TYPE, sortOptions.sortType);
    };

    const filterCount = computed(() => {
      let count = 0;
      filterPriorityCode.value === null || count++;
      filterStatusCode.value === null || count++;
      filterDateCreateFrom.value === null || count++;
      filterDateCreateTo.value === null || count++;
      filterDateUpdateFrom.value === null || count++;
      filterDateUpdateTo.value === null || count++;
      filterProduct.value === null || count++;
      filterSupportContact.value === null || count++;
      return count;
    });
    const noDataText = computed(() => {
      let hasTickets = tickets.value.length > 0;
      if (!hasTickets && isFetching.value) {
        return t("ticket-list-message-loading");
      }
      if (hasTickets) {
        return t("ticket-list-message-filter-empty");
      }
      return t("ticket-list-message-fetch-empty");
    });

    const readAllTickets = (token, c4c) => {
      return store.dispatch(Consts.ACTION_TICKETS_READ_ALL, { token, c4c });
    };

    const goToTicket = (ticket) => {
      router.push(
        getLocalizedUrl(consts("URL_TICKET_DETAIL")).replace(
          ":id",
          ticket.TicketID
        )
      );
    };

    const deleteDrawerFilters = () => {
      filterPriorityCode.value = null;
      filterStatusCode.value = null;
      filterDateCreateFrom.value = null;
      filterDateCreateTo.value = null;
      filterDateUpdateFrom.value = null;
      filterDateUpdateTo.value = null;
      filterProduct.value = null;
      filterSupportContact.value = null;
    };

    return {
      xs,
      isFetching,
      filterDrawerIsOpen,
      tableRowsPerPages,
      tableHeaders,
      filterSearch,
      filterOpenOnly,
      filterCount,
      tableItems,
      filterPagination,
      filterDateCreateFrom,
      filterDateCreateTo,
      filterDateUpdateFrom,
      filterDateUpdateTo,
      filterProduct,
      productItems,
      priorityItems,
      statusItems,
      supportContactItems,
      noDataText,
      filterSectionClass,
      filterPriorityCode,
      filterStatusCode,
      filterSupportContact,
      sortBy,
      sortType,
      updateSort,
      goToTicket,
      deleteDrawerFilters,
      getLocalizedUrl,
      consts,
    };
  },
});
</script>

<style lang="scss" scoped>
.ticket-list-table__progress {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ticket-list-table {
  .v-datatable__actions {
    font-size: 14px;
    .v-btn {
      color: #009dd6;
    }
  }
  .v-chip {
    .v-chip__content {
      cursor: pointer;
    }
  }
}
.ticket-list-table table.v-table thead td,
.ticket-list-table table.v-table tbody td,
.ticket-list-table table.v-table thead th,
.ticket-list-table table.v-table tbody th {
  font-size: 14px !important;
  padding: 0 8px !important;
}
.ticket-list-table table.v-table thead td:first-child,
.ticket-list-table table.v-table tbody td:first-child,
.ticket-list-table table.v-table thead th:first-child,
.ticket-list-table table.v-table tbody th:first-child {
  padding-left: 16px !important;
}
.ticket-list-table table.v-table thead td:last-child,
.ticket-list-table table.v-table tbody td:last-child,
.ticket-list-table table.v-table thead th:last-child,
.ticket-list-table table.v-table tbody th:last-child {
  padding-right: 16px !important;
}
</style>

import "@/styles/variables.scss";
import {createVuetify} from "vuetify";
import {aliases, md} from 'vuetify/iconsets/md'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const customTheme = {
    dark: false,
    colors: {
        primary: '#009DD6',
        secondary: '#0069AA',
        error: '#E52E2E'
    }
}
const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'customTheme',
        themes: {
            customTheme,
        }
    },
    icons: {
        defaultSet: 'md',
        aliases,
        sets: {
            md,
        }
    }
})

export default vuetify

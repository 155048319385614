<template>
  <div>
    <!-- Heading -->
    <v-container fluid grid-list-lg>
      <v-sheet color="transparent" :max-width="thresholds.md" class="mx-auto">
        <h1 class="text-h6 text-secondary">
          {{ $t("ticket-confirm-title") }}
        </h1>
      </v-sheet>
    </v-container>
    <!-- Card -->
    <v-container fluid :class="{ 'pt-0': true, 'px-0': xs }">
      <v-card :max-width="thresholds.md" class="mx-auto">
        <v-container fluid class="rm-child-margin">
          <h2 class="headline mb-3">
            {{ $t("ticket-confirm-subtitle") }}
          </h2>
          <p>
            {{ $t("ticket-confirm-label") }}
            <router-link :to="to">
              {{ ticketId }}
            </router-link>
          </p>
          <p>
            {{ $t("ticket-confirm-text") }}
          </p>
          <v-btn
            outline
            :block="xs"
            color="primary"
            class="ma-0"
            exact
            :to="getLocalizedUrl(consts('URL_HOME'))"
          >
            <v-icon left>arrow_back</v-icon>
            {{ $t("ticket-add-back") }}
          </v-btn>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import useLocalizedUrl from "@/composables/localizedUrl";
import useConsts from "@/composables/consts";
import { useDisplay } from "vuetify";

export default defineComponent({
  setup() {
    const route = useRoute();
    const ticketId = ref(null);
    const { thresholds, xs } = useDisplay();
    const { getLocalizedUrl } = useLocalizedUrl();
    const { consts } = useConsts();

    onMounted(() => {
      ticketId.value = route.params.id;
    });

    const to = computed(() => {
      return getLocalizedUrl(consts("URL_TICKET_DETAIL")).replace(
        ":id",
        ticketId.value
      );
    });

    return {
      xs,
      thresholds,
      ticketId,
      to,
      getLocalizedUrl,
      consts,
    };
  },
});
</script>

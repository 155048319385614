<template>
  <div>
    <v-container fluid grid-list-lg>
      <v-sheet color="transparent" :max-width="thresholds.sm" class="mx-auto">
        <slot name="header"></slot>
      </v-sheet>
    </v-container>
    <v-container fluid :class="{'pt-0': true, 'px-0': xs}">
      <v-card :max-width="thresholds.sm" class="mx-auto">
        <v-container fluid class="rm-child-margin">
          <slot name="default"></slot>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {VCard, VContainer, VSheet} from "vuetify/components";
import {useDisplay} from "vuetify";
import {defineComponent} from "vue";

export default defineComponent({
  component: {
    VContainer,
    VSheet,
    VCard,
  },
  setup() {
    const {thresholds, xs} = useDisplay();

    return {
      thresholds,
      xs
    }
  }
})
</script>

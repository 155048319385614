import Env from "./env";

const LANG_EN = "en";
const IS_LOCAL = Env.VUE_APP_ENVIRONMENT === "local";
const LOCAL_STORAGE_TOKEN = "token";
const PRIORITY_CODE = ["1", "2", "3", "7"];
const STATUS_CODE_CLOSED = ["6"];
const EXFO_DOMAIN = Env.VUE_APP_EXFO_DOMAIN;

const AUTH0_DOMAIN = Env.VUE_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = Env.VUE_APP_AUTH0_CLIENT_ID;

const PORTAL_API_BASE_URL = Env.VUE_APP_PORTAL_API;
const PORTAL_API_USER = `${PORTAL_API_BASE_URL}/user`;
const PORTAL_API_ACCOUNT_TICKETS_URL = `${PORTAL_API_BASE_URL}/tickets`;
const PORTAL_API_ACCOUNT_TICKETS_ALL = `${PORTAL_API_ACCOUNT_TICKETS_URL}/{c4c}/all`;
const PORTAL_API_ACCOUNT_TICKET_URL = `${PORTAL_API_ACCOUNT_TICKETS_URL}/{c4c}/{id}`;
const PORTAL_API_ACCOUNT_CREATE_TICKET_URL = `${PORTAL_API_ACCOUNT_TICKETS_URL}/{c4c}/create`;
const PORTAL_API_ACCOUNT_TICKET_ATTACHMENTS_URL = `${PORTAL_API_ACCOUNT_TICKETS_URL}/{c4c}/{id}/attachments`;
const PORTAL_API_ACCOUNT_TICKET_COMMENTS_URL = `${PORTAL_API_ACCOUNT_TICKETS_URL}/{c4c}/tickets/{id}/comments`;
const PORTAL_API_ACCOUNT_PRODUCTS_URL = `${PORTAL_API_ACCOUNT_TICKETS_URL}/products`;
const PORTAL_API_ACCOUNT_CONTRACTS_URL = `${PORTAL_API_ACCOUNT_TICKETS_URL}/{c4c}/contracts`;

const TOKEN_API_URL = Env.VUE_APP_TOKEN_API;
const TOKEN_API_USERNAME = Env.VUE_APP_TOKEN_API_USERNAME;
const TOKEN_API_PASSWORD = Env.VUE_APP_TOKEN_API_PASSWORD;

const URL_HOME = "url-home";
const URL_TICKET_LIST = "url-ticket-list";
const URL_TICKET_DETAIL = "url-ticket-detail";
const URL_TICKET_ADD = "url-ticket-add";
const URL_TICKET_CONFIRM = "url-ticket-confirm";

const ERROR_CODE_401 = 401;
const ERROR_CODE_403 = 403;
const ERROR_CODE_500 = 500;

const COMMENT_CODE_DESCRIPTION = "10004";
const COMMENT_CODE_TO_CUSTOMER = "10007";
const COMMENT_CODE_FROM_CUSTOMER = "10008";

const STATE_APP_IS_VISIBLE = (state) => state.app.isVisible;
const STATE_APP_IS_ERROR_403 = (state) => state.app.isError403;
const STATE_APP_IS_ERROR_500 = (state) => state.app.isError500;
const STATE_ACCOUNT_IS_LOGGED = (state) => state.account.isLogged;
const STATE_SNACKBAR_LIST = (state) => state.snackbar.list;
const STATE_TICKETS_LIST = (state) => state.tickets.list;
const STATE_FILTERS_SEARCH = (state) => state.filters.search;
const STATE_FILTERS_OPEN_ONLY = (state) => state.filters.openOnly;
const STATE_FILTERS_DATE_CREATE_FROM = (state) => state.filters.dateCreateFrom;
const STATE_FILTERS_DATE_CREATE_TO = (state) => state.filters.dateCreateTo;
const STATE_FILTERS_DATE_UPDATE_FROM = (state) => state.filters.dateUpdateFrom;
const STATE_FILTERS_DATE_UPDATE_TO = (state) => state.filters.dateUpdateTo;
const STATE_FILTERS_PRODUCT = (state) => state.filters.product;
const STATE_FILTERS_PRIORITY_CODE = (state) => state.filters.priorityCode;
const STATE_FILTERS_STATUS_CODE = (state) => state.filters.statusCode;
const STATE_FILTERS_SUPPORT_CONTACT = (state) => state.filters.supportContact;
const STATE_FILTERS_PAGINATION = (state) => state.filters.pagination;
const STATE_FILTERS_SORT_BY = (state) => state.filters.sortBy;
const STATE_FILTERS_SORT_TYPE = (state) => state.filters.sortType;
const STATE_CONTRACTS_LIST = (state) => state.contracts.list;
const STATE_PRODUCTS_LIST = (state) => state.products.list;

const GETTER_ACCOUNT_FULLNAME = "account/fullname";

const MUTATION_APP_IS_ERROR_403 = "app/isError403";
const MUTATION_APP_IS_ERROR_500 = "app/isError500";
const MUTATION_ACCOUNT_FIRSTNAME = "account/firstname";
const MUTATION_ACCOUNT_LASTNAME = "account/lastname";
const MUTATION_ACCOUNT_IS_LOGGED = "account/isLogged";
const MUTATION_ACCOUNT_C4CCONTACTID = "account/c4cContactId";
const MUTATION_SNACKBAR_ADD_ITEM = "snackbar/addItem";
const MUTATION_SNACKBAR_DELETE_ITEM = "snackbar/deleteItem";
const MUTATION_FILTERS_SEARCH = "filters/search";
const MUTATION_FILTERS_OPEN_ONLY = "filters/openOnly";
const MUTATION_FILTERS_DATE_CREATE_FROM = "filters/dateCreateFrom";
const MUTATION_FILTERS_DATE_CREATE_TO = "filters/dateCreateTo";
const MUTATION_FILTERS_DATE_UPDATE_FROM = "filters/dateUpdateFrom";
const MUTATION_FILTERS_DATE_UPDATE_TO = "filters/dateUpdateTo";
const MUTATION_FILTERS_PRODUCT = "filters/product";
const MUTATION_FILTERS_PRIORITY_CODE = "filters/priorityCode";
const MUTATION_FILTERS_STATUS_CODE = "filters/statusCode";
const MUTATION_FILTERS_SUPPORT_CONTACT = "filters/supportContact";
const MUTATION_FILTERS_PAGINATION = "filters/pagination";
const MUTATION_FILTERS_SORT_BY = "filters/sortBy";
const MUTATION_FILTERS_SORT_TYPE = "filters/sortType";

const ACTION_APP_VISIBILITY_UPDATE = "app/updateVisibility";
const ACTION_ACCOUNT_READ = "account/readAccount";
const ACTION_TICKETS_READ_ALL = "tickets/readAllTickets";
const ACTION_TICKETS_READ_ONE = "tickets/readTicket";
const ACTION_CONTRACTS_READ_ALL = "contracts/readAllContracts";
const ACTION_PRODUCTS_READ_ALL = "products/readAllProducts";

export const Consts = {
  LANG_EN,
  IS_LOCAL,
  LOCAL_STORAGE_TOKEN,
  PRIORITY_CODE,
  STATUS_CODE_CLOSED,
  EXFO_DOMAIN,

  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,

  PORTAL_API_USER,
  PORTAL_API_ACCOUNT_TICKETS_URL,
  PORTAL_API_ACCOUNT_TICKETS_ALL,
  PORTAL_API_ACCOUNT_TICKET_URL,
  PORTAL_API_ACCOUNT_CREATE_TICKET_URL,
  PORTAL_API_ACCOUNT_TICKET_ATTACHMENTS_URL,
  PORTAL_API_ACCOUNT_TICKET_COMMENTS_URL,
  PORTAL_API_ACCOUNT_PRODUCTS_URL,
  PORTAL_API_ACCOUNT_CONTRACTS_URL,

  TOKEN_API_URL,
  TOKEN_API_USERNAME,
  TOKEN_API_PASSWORD,

  URL_HOME,
  URL_TICKET_LIST,
  URL_TICKET_DETAIL,
  URL_TICKET_ADD,
  URL_TICKET_CONFIRM,

  ERROR_CODE_401,
  ERROR_CODE_403,
  ERROR_CODE_500,

  COMMENT_CODE_DESCRIPTION,
  COMMENT_CODE_TO_CUSTOMER,
  COMMENT_CODE_FROM_CUSTOMER,

  STATE_APP_IS_VISIBLE,
  STATE_APP_IS_ERROR_403,
  STATE_APP_IS_ERROR_500,
  STATE_ACCOUNT_IS_LOGGED,
  STATE_SNACKBAR_LIST,
  STATE_TICKETS_LIST,
  STATE_FILTERS_SEARCH,
  STATE_FILTERS_OPEN_ONLY,
  STATE_FILTERS_DATE_CREATE_FROM,
  STATE_FILTERS_DATE_CREATE_TO,
  STATE_FILTERS_DATE_UPDATE_FROM,
  STATE_FILTERS_DATE_UPDATE_TO,
  STATE_FILTERS_PRODUCT,
  STATE_FILTERS_PRIORITY_CODE,
  STATE_FILTERS_STATUS_CODE,
  STATE_FILTERS_SUPPORT_CONTACT,
  STATE_FILTERS_PAGINATION,
  STATE_FILTERS_SORT_BY,
  STATE_FILTERS_SORT_TYPE,
  STATE_CONTRACTS_LIST,
  STATE_PRODUCTS_LIST,

  GETTER_ACCOUNT_FULLNAME,

  MUTATION_APP_IS_ERROR_403,
  MUTATION_APP_IS_ERROR_500,
  MUTATION_ACCOUNT_FIRSTNAME,
  MUTATION_ACCOUNT_LASTNAME,
  MUTATION_ACCOUNT_IS_LOGGED,
  MUTATION_ACCOUNT_C4CCONTACTID,
  MUTATION_SNACKBAR_ADD_ITEM,
  MUTATION_SNACKBAR_DELETE_ITEM,
  MUTATION_FILTERS_SEARCH,
  MUTATION_FILTERS_OPEN_ONLY,
  MUTATION_FILTERS_DATE_CREATE_FROM,
  MUTATION_FILTERS_DATE_CREATE_TO,
  MUTATION_FILTERS_DATE_UPDATE_FROM,
  MUTATION_FILTERS_DATE_UPDATE_TO,
  MUTATION_FILTERS_PRODUCT,
  MUTATION_FILTERS_PRIORITY_CODE,
  MUTATION_FILTERS_STATUS_CODE,
  MUTATION_FILTERS_SUPPORT_CONTACT,
  MUTATION_FILTERS_PAGINATION,
  MUTATION_FILTERS_SORT_BY,
  MUTATION_FILTERS_SORT_TYPE,

  ACTION_APP_VISIBILITY_UPDATE,
  ACTION_ACCOUNT_READ,
  ACTION_TICKETS_READ_ALL,
  ACTION_TICKETS_READ_ONE,
  ACTION_CONTRACTS_READ_ALL,
  ACTION_PRODUCTS_READ_ALL,
};

const overrides = window.ticket_env || {};

export default {
  VUE_APP_ENVIRONMENT:
    overrides.VUE_APP_ENVIRONMENT || process.env.VUE_APP_ENVIRONMENT,
  VUE_APP_PORTAL_API:
    overrides.VUE_APP_PORTAL_API || process.env.VUE_APP_PORTAL_API,
  VUE_APP_TOKEN_API:
    overrides.VUE_APP_TOKEN_API || process.env.VUE_APP_TOKEN_API,
  VUE_APP_TOKEN_API_USERNAME:
    overrides.VUE_APP_TOKEN_API_USERNAME ||
    process.env.VUE_APP_TOKEN_API_USERNAME,
  VUE_APP_TOKEN_API_PASSWORD:
    overrides.VUE_APP_TOKEN_API_PASSWORD ||
    process.env.VUE_APP_TOKEN_API_PASSWORD,
  VUE_APP_EXFO_DOMAIN:
    overrides.VUE_APP_EXFO_DOMAIN || process.env.VUE_APP_EXFO_DOMAIN,
  VUE_APP_AUTH0_DOMAIN:
    overrides.VUE_APP_AUTH0_DOMAIN || process.env.VUE_APP_AUTH0_DOMAIN,
  VUE_APP_AUTH0_CLIENT_ID:
    overrides.VUE_APP_AUTH0_CLIENT_ID || process.env.VUE_APP_AUTH0_CLIENT_ID,
};

import { Consts } from "@/helpers/consts";
import { Get } from "@/helpers/fetch";

const readAllContracts = (token, c4c) =>
  new Promise((resolve, reject) => {
    Get({
      url: Consts.PORTAL_API_ACCOUNT_CONTRACTS_URL.replace('{c4c}', c4c),
      bearerToken: token,
    })
      .then((res) => res.json())
      .then((json) => {
        resolve(json);
      })
      .catch(() => {
        reject();
      });
  });

export default {
  readAllContracts,
};

import {createStore} from 'vuex'
import app from './modules/app'
import account from './modules/account'
import tickets from './modules/tickets'
import filters from './modules/filters'
import contracts from './modules/contracts'
import products from './modules/products'
import snackbar from './modules/snackbar'


const store = createStore({
    modules: {
        app,
        account,
        tickets,
        filters,
        contracts,
        products,
        snackbar
    }
})

export default store

import router from "@/helpers/router";

export default function useLocalizedUrl() {
  function getLocalizedUrl(key) {
    return router.getLocalizedUrl(key);
  }

  return {
    getLocalizedUrl,
  };
}

export const Fetch = (url, options) => {
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((res) => {
        if (res.status !== 200) {
          reject();
          return;
        }
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const Get = (obj) => {
  let url = obj.url || "";
  let options = obj.options || {};
  let bearerToken = obj.bearerToken || null;

  options = {
    method: "GET",
    ...options,
  };

  options.headers = options.headers || {};

  if (bearerToken) {
    options.headers.Authorization = `Bearer ${bearerToken}`;
  }

  options.headers["pragma"] = "no-cache";
  options.headers["cache-control"] = "no-cache";

  return Fetch(url, options);
};

export const Post = (obj) => {
  let url = obj.url || "";
  let options = obj.options || {};
  let bearerToken = obj.bearerToken || null;

  if (bearerToken) {
    options = {
      method: "POST",
      body: JSON.stringify(obj.body),
      ...options,
    };
    options.headers = options.headers || {};
    options.headers["Content-Type"] = "application/json";
    options.headers.Authorization = `Bearer ${bearerToken}`;
  }
  else
  {
    options = {
      method: "POST",
      body: obj.body,
      ...options,
    };
  }

  return Fetch(url, options);
};

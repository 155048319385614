import contractsService from "@/services/contracts";

const state = {
  list: [],
};

const mutations = {
  addItem(state, contract) {
    state.list = state.list.filter((c) => c.ContractID !== contract.ContractID);
    state.list.push(contract);
  },
};

const actions = {
  readAllContracts({ commit }, { token, c4c }) {
    return new Promise((resolve, reject) => {
      contractsService
        .readAllContracts(token, c4c)
        .then((contracts) => {
          contracts.forEach((contract) => commit("addItem", contract));
          resolve(token);
        })
        .catch(() => {
          // TODO : Dispatch alert
          resolve(token);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

import { createI18n } from "vue-i18n";
import { Consts } from "@/helpers/consts";

const locale = (function () {
  let pathname = location.pathname.split("/");
  let lang = pathname[0] || pathname[1];
  switch (lang.toLowerCase()) {
    case Consts.LANG_EN:
    default:
      return Consts.LANG_EN;
  }
})();

function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const instanceI18n = createI18n({
  locale: locale,
  legacy: false,
  globalInjection: true,
  fallbackLocale: Consts.LANG_EN,
  messages: loadLocaleMessages(),
  datetimeFormats: {
    en: {
      date: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      datetime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
    },
  },
});

export default instanceI18n;

<template>
  <v-chip
    size="small"
    :color="color"
    text-color="black"
    class="chip-priority ma-0"
    variant="outlined"
  >
    {{ $t(`priority-code-${code}`) }}
  </v-chip>
</template>

<script>
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "chip-priority",
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const color = computed(() => {
      switch (props.code) {
        case "1":
          return "#f24533";
        case "2":
          return "#fba500";
        case "3":
          return "#7dc242";
        case "7":
          return "#20af6b";
        default:
          return "grey";
      }
    });

    return {
      color,
    };
  },
});
</script>

<style lang="scss" scoped>
.chip-priority {
  &.v-chip {
    &.v-chip {
      &.v-chip--outline {
        border-width: 2px;
      }
    }
  }
}
</style>

<template>
  <v-app v-show="isVisible">
    <nav-main />
    <nav-developper v-if="consts('IS_LOCAL')" />
    <notification-snackbar />
    <v-main>
      <!--<button @click="getToken">Get Token</button>-->
      <error-500 v-if="isError500" />
      <error-403 v-else-if="isError403" />
      <router-view v-else />
    </v-main>
  </v-app>
</template>

<script>
import { Consts } from "@/helpers/consts";
import NavMain from "@/components/nav/main";
import NavDevelopper from "@/components/nav/developper";
import NotificationSnackbar from "@/components/notification/snackbar";
import Error403 from "@/components/error/403.vue";
import Error500 from "@/components/error/500.vue";
import { VApp, VMain } from "vuetify/components";
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import useConsts from "@/composables/consts";
import { useAuth0 } from "@auth0/auth0-vue";
import useAuth0Api from "@/composables/auth0";

export default defineComponent({
  components: {
    NavMain,
    NavDevelopper,
    NotificationSnackbar,
    Error403,
    Error500,
    VApp,
    VMain,
  },
  setup() {
    const store = useStore();
    const { consts } = useConsts();
    const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const { clearC4cValue, clearTokenValue, getTokenValue } = useAuth0Api();
    const isVisible = computed(() => Consts.STATE_APP_IS_VISIBLE(store.state));
    const isError403 = computed(() =>
      Consts.STATE_APP_IS_ERROR_403(store.state)
    );
    const isError500 = computed(() =>
      Consts.STATE_APP_IS_ERROR_500(store.state)
    );

    onMounted(async () => {
      try {
        await getAccessTokenSilently();
        store.commit(Consts.MUTATION_ACCOUNT_IS_LOGGED, true);
        store.commit(Consts.MUTATION_ACCOUNT_FIRSTNAME, user.value.name);
        store.commit(Consts.MUTATION_APP_IS_ERROR_403, false);
        store.commit(Consts.MUTATION_APP_IS_ERROR_500, false);
        await store.dispatch(Consts.ACTION_APP_VISIBILITY_UPDATE, true);
      } catch (e) {
        await loginWithRedirect();
        store.commit(Consts.MUTATION_ACCOUNT_IS_LOGGED, false);
        store.commit(Consts.MUTATION_ACCOUNT_FIRSTNAME, "");
        store.commit(Consts.MUTATION_APP_IS_ERROR_403, true);
        store.commit(Consts.MUTATION_APP_IS_ERROR_500, true);
        await store.dispatch(Consts.ACTION_APP_VISIBILITY_UPDATE, true);
        clearC4cValue();
        clearTokenValue();
      }
    });

    async function getToken() {
      try {
        const token = await getTokenValue();
        console.log(token);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      isVisible,
      isError403,
      isError500,
      consts,
      getToken,
    };
  },
});
</script>

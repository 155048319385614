import ticketsService from "@/services/tickets";
import i18n from "@/helpers/i18n";

const state = {
  list: [],
};

const mutations = {
  addItems(state, tickets) {
    let ticketsId = tickets.map((t) => t.TicketID);
    let list = state.list.filter((t) => !ticketsId.includes(t.TicketID));

    let cache = {};
    state.list
      .filter((t) => ticketsId.includes(t.TicketID))
      .forEach((ticket) => {
        cache[ticket.TicketID] = ticket;
      });

    tickets.forEach((ticket) => {
      let prevTicket = cache[ticket.TicketID] || {};
      list.push({
        ...ticket,
        Attachments: ticket.Attachments
          ? ticket.Attachments
          : prevTicket.Attachments
          ? prevTicket.Attachments
          : null,
        Comments: ticket.Comments
          ? ticket.Comments
          : prevTicket.Comments
          ? prevTicket.Comments
          : null,
        StatusText: ticket.StatusCode
          ? i18n.global.t(`status-code-${ticket.StatusCode}`)
          : "",
      });
    });

    state.list = list;
  },
};

const actions = {
  readAllTickets({ commit }, { token, c4c }) {
    return new Promise((resolve, reject) => {
      ticketsService
        .readAllTickets(token, c4c)
        .then((tickets) => {
          commit("addItems", tickets);
          resolve(token);
        })
        .catch(() => {
          // TODO : Dispatch alert
          resolve(token);
        });
    });
  },
  readTicket({ commit }, { token, ticketId, c4c }) {
    return new Promise((resolve, reject) => {
      ticketsService
        .readTicket(token, ticketId, c4c)
        .then((ticket) => {
          commit("addItems", [ticket]);
          resolve(token);
        })
        .catch(() => {
          // TODO : Dispatch alert
          resolve(token);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

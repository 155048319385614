export default {
  required: str => {
    return !(str === undefined || str === null || str.trim() === '')
  },
  date: str => {
    var date_regex = /^(0[1-9]|1[0-2])\/(19|20)\d{2}$/
    return date_regex.test(str)
  },
  email: str => {
    return RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}').test(str)
  }
}
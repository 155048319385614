<template>
  <error-layout>
    <template v-slot:header>
      <h1 class="text-h6 text-secondary">
        {{ $t('error-403-title') }}
      </h1>
    </template>
    <template v-slot:default>
      <p v-html="$t('error-403-text')" class="ma-0"></p>
    </template>
  </error-layout>
</template>

<script>
import ErrorLayout from './layout'
import {defineComponent} from "vue";

export default defineComponent({
  components: {
    ErrorLayout
  }
})
</script>

import { Consts } from "@/helpers/consts";

export default function useConsts() {
  function consts(key) {
    return Consts[key];
  }

  return {
    consts,
  };
}

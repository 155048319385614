const state = {
  firstname: "",
  lastname: "",
  isLogged: false,
  c4cContactId: "",
};

const getters = {
  fullname(state) {
    return `${state.firstname} ${state.lastname}`.trim();
  },
};

const mutations = {
  firstname(state, firstname) {
    state.firstname = firstname;
  },
  lastname(state, lastname) {
    state.lastname = lastname;
  },
  isLogged(state, isLogged) {
    state.isLogged = isLogged;
  },
  c4cContactId(state, c4cContactId) {
    state.c4cContactId = c4cContactId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

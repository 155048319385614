import { Consts } from '@/helpers/consts'
import { Get } from '@/helpers/fetch'

const readAllProducts = token => new Promise((resolve, reject) => {
  Get({
    url: Consts.PORTAL_API_ACCOUNT_PRODUCTS_URL,
    bearerToken: token
  })
  .then(res => res.json())
  .then(json => {
    resolve(json)
  })
  .catch(() => {
    reject()
  })
})

export default {
  readAllProducts
}

<template>
  <v-form @submit.prevent="submit">
    <v-text-field
      v-model="search"
      variant="solo"
      hide-details
      :placeholder="$t('form-search-exfo-placeholder')"
      class="px-2"
      :style="{
        width: props.width ? `${props.width}px` : '100%',
      }"
    >
      <template v-slot:append>
        <v-btn
          flat
          color="primary"
          variant="tonal"
          type="submit"
          class="ma-0 ml-2 px-2"
          style="min-width: 0"
        >
          {{ $t("form-search-exfo-submit") }}
        </v-btn>
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
import { Consts } from "@/helpers/consts";
import { ref, defineComponent } from "vue";
import { VBtn, VForm, VTextField } from "vuetify/components";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    VForm,
    VTextField,
    VBtn,
  },
  props: {
    width: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const search = ref("");
    const { t } = useI18n();

    const submit = () => {
      window.location.href = t("form-search-exfo-url", {
        domain: Consts.EXFO_DOMAIN,
        search: encodeURIComponent(search.value),
      });
    };

    return {
      props,
      search,
      submit,
    };
  },
});
</script>

import {createRouter, createWebHashHistory} from 'vue-router'
import {Consts} from '@/helpers/consts'
import i18n from '@/helpers/i18n'
import Url from '@/helpers/url'

import ViewTicketList from '@/views/ticket-list.vue'
import ViewTicketAdd from '@/views/ticket-add.vue'
import ViewTicketConfirm from '@/views/ticket-confirm.vue'
import ViewTicketDetail from '@/views/ticket-detail.vue'

const defaultMeta = {}

const routes = [
    {
        key: Consts.URL_TICKET_LIST,
        component: ViewTicketList,
        children: [
            {
                key: Consts.URL_TICKET_ADD,
                component: ViewTicketAdd
            },
            {
                key: Consts.URL_TICKET_CONFIRM,
                component: ViewTicketConfirm
            },
            {
                key: Consts.URL_TICKET_DETAIL,
                component: ViewTicketDetail
            }
        ]
    },
    {
        key: Consts.URL_HOME,
        redirect: () => getLocalizedUrl(Consts.URL_TICKET_LIST)
    }
]

let localizedUrlByKey = {}

function generateRoute(routes, locale, basePath = '') {
    let response = []

    routes.forEach(route => {
        let meta = {...defaultMeta, ...route.meta, key: route.key}
        let path = (basePath !== '' ? basePath + '/' : '/') + (route.path ? route.path : Url.getUrl(route.key, locale))

        localizedUrlByKey[locale] = localizedUrlByKey[locale] || {}
        localizedUrlByKey[locale][route.key] = path

        let routeItem = null
        if (route.redirect) {
            routeItem = {
                path,
                meta,
                redirect: route.redirect
            }
        } else {
            routeItem = {
                path,
                component: route.component,
                meta
            }
        }

        let children = []
        if (route.children) {
            children = generateRoute(route.children, locale, path).flattenDeep()
        }

        response = [...response, ...children, ...[routeItem]]
    })

    return response
}
const getLocalizedUrl = key => {
    if (localizedUrlByKey[i18n.global.locale.value][key] !== undefined) {
        return localizedUrlByKey[i18n.global.locale.value][key]
    }
    return localizedUrlByKey[i18n.global.locale.value][Consts.URL_HOME]
}

const instance = createRouter({
    history: createWebHashHistory(),
    base: location.pathname,
    routes: [
        ...generateRoute(routes, Consts.LANG_EN),
        {
            path: "/:catchAll(.*)",
            redirect: () => getLocalizedUrl(Consts.URL_HOME)
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
});

export default {
    instance,
    getLocalizedUrl
}

<template>
  <div class="nav-main">
    <v-toolbar color="secondary">
      <v-app-bar-nav-icon
        v-if="smAndDown"
        @click="drawerIsOpen = !drawerIsOpen"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-h4 font-weight-light">
        <router-link
          :to="getLocalizedUrl(consts('URL_HOME'))"
          class="text-white"
          style="text-decoration: none"
        >
          <template v-if="smAndUp">
            <v-img
              height="1em"
              :width="`${218 / 66}em`"
              contain
              :src="require('@/assets/exfo-white.png')"
              class="nav-main__desktop-logo"
            />
            <p class="nav-main__desktop-title">{{ $t("nav-main-title") }}</p>
          </template>
          <template v-else>
            <p class="ma-0">{{ $t("nav-main-title") }}</p>
          </template>
        </router-link>
      </v-toolbar-title>
      <!-- Desktop navigation -->
      <template v-if="mdAndUp">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- Help -->
          <v-btn flat :href="hrefHelp">
            <v-icon class="mr-2" icon="help" size="lg"></v-icon>
            {{ $t("nav-main-help-label") }}
          </v-btn>
          <!-- User -->
          <v-menu v-if="isLogged" :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn flat v-bind="props">
                <v-icon class="mr-2" icon="account_circle" size="lg"></v-icon>
                {{ $t("nav-main-user-label", { name: userName }) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item :href="hrefUserEdit">
                <template v-slot:prepend>
                  <v-icon class="mr-2" icon="edit" size="lg"></v-icon>
                </template>
                <v-list-item-title>
                  {{ $t("nav-main-user-edit-label") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleUserLogout">
                <template v-slot:prepend>
                  <v-icon class="mr-2" icon="exit_to_app"></v-icon>
                </template>
                <v-list-item-title>
                  {{ $t("nav-main-user-logout-label") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- Search -->
          <v-menu offset-y left :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" style="min-width: 0">
                <v-icon icon="search" size="lg"></v-icon>
              </v-btn>
            </template>
            <div class="bg-white py-1">
              <form-search-exfo :width="350" />
            </div>
          </v-menu>
          <!-- Apps -->
          <v-menu offset-y left :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn flat v-bind="props" style="min-width: 0">
                <v-icon icon="apps" size="lg"></v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :href="hrefExfo">
                <v-list-item-title>{{
                  $t("nav-main-app-exfo-label")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </template>
    </v-toolbar>
    <!-- Mobile navigation -->
    <v-navigation-drawer v-model="drawerIsOpen" app temporary width="280">
      <!-- Heading -->
      <div class="pa-3 text-secondary text-h6 font-weight-regular">
        <router-link
          :to="getLocalizedUrl(consts('URL_HOME'))"
          class="text-secondary"
          style="text-decoration: none"
        >
          <v-img
            height="1em"
            :width="`${218 / 66}em`"
            contain
            :src="require('@/assets/exfo-blue.png')"
            class="mb-2"
          />
          <p class="ma-0">
            {{ $t("nav-main-title") }}
          </p>
        </router-link>
      </div>
      <v-divider />
      <!-- Search -->
      <div class="pl-1 py-1">
        <form-search-exfo />
      </div>
      <v-divider />
      <!-- User -->
      <template v-if="isLogged">
        <v-list>
          <v-list-item-subtitle class="caption text-uppercase"
            >{{ $t("nav-main-user-label", { name: userName }) }}
          </v-list-item-subtitle>
          <v-list-item :href="hrefUserEdit">
            <v-list-item-title>
              {{ $t("nav-main-user-edit-label") }}
            </v-list-item-title>
            <template v-slot:prepend>
              <v-icon icon="edit" class="mr-2"></v-icon>
            </template>
          </v-list-item>
          <v-list-item @click="handleUserLogout">
            <v-list-item-title>
              {{ $t("nav-main-user-logout-label") }}
            </v-list-item-title>
            <template v-slot:prepend>
              <v-icon icon="exit_to_app" class="mr-2"></v-icon>
            </template>
          </v-list-item>
        </v-list>
        <v-divider />
      </template>
      <!-- Help -->
      <v-list>
        <v-list-item :href="hrefHelp">
          <v-list-item-title>
            {{ $t("nav-main-help-label") }}
          </v-list-item-title>
          <template v-slot:prepend>
            <v-icon icon="help" class="mr-2"></v-icon>
          </template>
        </v-list-item>
      </v-list>
      <v-divider />
      <!-- Apps -->
      <v-list v-if="true">
        <v-list-item-subtitle class="caption text-uppercase">{{
          $t("nav-main-app-label")
        }}</v-list-item-subtitle>

        <v-list-item :href="hrefExfo">
          <v-list-item-title>
            {{ $t("nav-main-app-exfo-label") }}
          </v-list-item-title>
          <template v-slot:prepend>
            <v-icon class="mr-2"></v-icon>
          </template>
        </v-list-item>
      </v-list>
      <v-divider />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { Consts } from "@/helpers/consts";
import { useStore } from "vuex";
import FormSearchExfo from "@/components/form/search-exfo";
import { computed, defineComponent, ref } from "vue";
import { useDisplay } from "vuetify";
import { useI18n } from "vue-i18n";
import useLocalizedUrl from "@/composables/localizedUrl";
import useConsts from "@/composables/consts";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter } from "vue-router";
import useAuth0Api from "@/composables/auth0";

export default defineComponent({
  components: {
    FormSearchExfo,
  },
  setup() {
    const drawerIsOpen = ref(false);
    const { smAndDown, smAndUp, mdAndUp } = useDisplay();
    const { t } = useI18n();
    const store = useStore();
    const { getLocalizedUrl } = useLocalizedUrl();
    const { consts } = useConsts();
    const { logout } = useAuth0();
    const { clearC4cValue, clearTokenValue } = useAuth0Api();
    const router = useRouter();

    const isLogged = computed(() =>
      Consts.STATE_ACCOUNT_IS_LOGGED(store.state)
    );
    const fullname = computed(
      () => store.getters[Consts.GETTER_ACCOUNT_FULLNAME]
    );

    const userName = computed(() => {
      return fullname.value || t("nav-main-user-placeholder");
    });

    const hrefExfo = computed(() => {
      return t("nav-main-app-exfo-url", { domain: Consts.EXFO_DOMAIN });
    });

    const hrefHelp = computed(() => {
      return t("nav-main-help-url", { domain: Consts.EXFO_DOMAIN });
    });

    const hrefUserEdit = computed(() => {
      return t("user-edit-url", { domain: Consts.EXFO_DOMAIN });
    });

    const handleUserLogout = async () => {
      store.commit(Consts.MUTATION_ACCOUNT_IS_LOGGED, false);
      store.commit(Consts.MUTATION_ACCOUNT_FIRSTNAME, "");
      clearC4cValue();
      clearTokenValue();
      await logout({ logoutParams: { returnTo: Consts.EXFO_DOMAIN } });
    };

    return {
      drawerIsOpen,
      smAndDown,
      smAndUp,
      mdAndUp,
      isLogged,
      fullname,
      userName,
      hrefExfo,
      hrefHelp,
      hrefUserEdit,
      handleUserLogout,
      getLocalizedUrl,
      consts,
    };
  },
});
</script>

<style lang="scss" scoped>
.nav-main__desktop-logo {
  display: inline-block !important;
  transform: translateY(0.15em);
}
.nav-main__desktop-title {
  display: inline-block !important;
  margin: 0;
  padding-left: 32px;
  position: relative;
  &::before {
    background: currentColor;
    opacity: 0.75;
    content: "";
    display: block;
    height: 1em;
    width: 1px;
    position: absolute;
    top: 0.1em;
    left: 16px;
  }
}
</style>

import { Get, Post } from "@/helpers/fetch";
import { Consts } from "@/helpers/consts";
import { useAuth0 } from "@auth0/auth0-vue";

export default function useAuth0Api() {
  const { user, getAccessTokenSilently } = useAuth0();
  function getUserInfo(userId, accessToken) {
    return new Promise((resolve, reject) => {
      Get({
        url: `${Consts.PORTAL_API_USER}/${userId}`,
        options: {
          headers: {
            "Content-Type": "application/json",
          },
        },
        bearerToken: accessToken,
      })
        .then((res) => res.json())
        .then((userInfo) => {
          resolve(userInfo);
        })
        .catch((e) => {
          console.log(e);
          reject();
        });
    });
  }

  function getOAuthToken() {
    const url = Consts.TOKEN_API_URL;
    const username = Consts.TOKEN_API_USERNAME;
    const password = Consts.TOKEN_API_PASSWORD;

    const formData = new URLSearchParams();
    formData.append("grant_type", "password");
    formData.append("username", username);
    formData.append("password", password);

    return new Promise((resolve, reject) => {
      Post({
        url: url,
        options: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
        body: decodeURIComponent(formData.toString()),
      })
        .then((res) => res.json())
        .then((json) => {
          resolve(json);
        })
        .catch(() => {
          reject();
        });
    });
  }

  async function getTokenValue() {
    try {
      const res = await getOAuthToken();
      console.log(res.access_token);
      return res.access_token;
    } catch (e) {
      console.log(e);
    }
  }

  async function getC4cValue() {
    const c4cContact = localStorage.getItem("c4cContactId");
    try {
      if (c4cContact) {
        return c4cContact;
      } else {
        const accessToken = await getAccessTokenSilently();
        const accessApiToken = await getTokenValue();
        const userInfo = await getUserInfo(user.value.sub, accessApiToken);
        const value = userInfo.app_metadata.c4c.c4cContactId;
        if (!value) {
          return Promise.reject("No c4c contact id");
        } else {
          localStorage.setItem("c4cContactId", value);
          return value;
        }
      }
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  function clearC4cValue() {
    localStorage.removeItem("c4cContactId");
  }

  function clearTokenValue() {
    localStorage.removeItem("token");
  }

  return {
    getOAuthToken,
    getC4cValue,
    getTokenValue,
    clearC4cValue,
    clearTokenValue,
  };
}

import { Consts } from "@/helpers/consts";
import { Get, Post } from "@/helpers/fetch";

const readAllTickets = (token, c4c) =>
  new Promise((resolve, reject) => {
    Get({
      url: Consts.PORTAL_API_ACCOUNT_TICKETS_ALL.replace("{c4c}", c4c),
      bearerToken: token,
    })
      .then((res) => res.json())
      .then((json) => {
        resolve(json);
      })
      .catch(() => {
        reject();
      });
  });

const readTicket = (token, ticketId, c4c) =>
  new Promise((resolve, reject) => {
    Get({
      url: Consts.PORTAL_API_ACCOUNT_TICKET_URL.replace("{c4c}", c4c).replace(
        "{id}",
        ticketId
      ),
      bearerToken: token,
    })
      .then((res) => res.json())
      .then((json) => {
        resolve(json);
      })
      .catch(() => {
        reject();
      });
  });

const createTicket = (token, ticket, c4c) =>
  new Promise((resolve, reject) => {
    Post({
      url: Consts.PORTAL_API_ACCOUNT_CREATE_TICKET_URL.replace("{c4c}", c4c),
      bearerToken: token,
      body: ticket,
    })
      .then((res) => res.json())
      .then((json) => {
        resolve(json);
      })
      .catch(() => {
        reject();
      });
  });

const addComment = (token, ticketId, comment, c4c) =>
  new Promise((resolve, reject) => {
    Post({
      url: Consts.PORTAL_API_ACCOUNT_TICKET_COMMENTS_URL.replace(
        "{id}",
        ticketId
      ).replace("{c4c}", c4c),
      bearerToken: token,
      body: { text: comment },
    })
      .then((result) => {
        if (result) {
          resolve(token);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });

const addAttachment = (token, ticketId, c4c, files) =>
  new Promise((resolve, reject) => {
    Post({
      url: Consts.PORTAL_API_ACCOUNT_TICKET_ATTACHMENTS_URL.replace(
        "{id}",
        ticketId
      ).replace("{c4c}", c4c),
      bearerToken: token,
      body: files,
    })
      .then((result) => {
        if (result) {
          resolve(token);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });

export default {
  readAllTickets,
  readTicket,
  createTicket,
  addComment,
  addAttachment,
};

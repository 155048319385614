<template>
  <v-snackbar
    v-model="hasItem"
    :key="item.id"
    :timeout="0"
    bottom
    v-bind="item"
  >
    {{ item.text }}
    <v-btn flat @click="deleteItem"> XXXX </v-btn>
  </v-snackbar>
</template>

<script>
import { Consts } from "@/helpers/consts";
import { useStore } from "vuex";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { VBtn, VSnackbar } from "vuetify/components";

export default defineComponent({
  components: {
    VSnackbar,
    VBtn,
  },

  setup() {
    const store = useStore();
    const timeout = ref(null);

    onMounted(() => {
      startTimeout();
    });

    const item = computed(() => {
      return list[0] || {};
    });

    const hasItem = computed(() => {
      return list.length > 0;
    });

    const list = computed(() => Consts.STATE_SNACKBAR_LIST(store.state));

    const startTimeout = () => {
      if (!hasItem) {
        return;
      }
      clearTimeout(timeout);
      if (item.value.timeout) {
        timeout.value = setTimeout(deleteItem, item.timeout);
      }
    };

    const deleteItem = () => {
      clearTimeout(timeout);
      store.commit(Consts.MUTATION_SNACKBAR_DELETE_ITEM, item.value.id);
    };

    watch(
      () => list,
      () => {
        startTimeout();
      }
    );

    return {
      timeout,
      item,
      hasItem,
      list,
      startTimeout,
      deleteItem,
    };
  },
});
</script>

<template>
  <v-menu location="top bottom" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn
        color="red"
        small
        fixed
        bottom
        position="fixed"
        location="bottom left"
        v-bind="props"
        icon="vpn_key"
        size="small"
        style="z-index: 9999"
      >
      </v-btn>
    </template>
    <div class="bg-white" style="width: 500px">
      <header class="caption text-uppercase">C4C Contact Id</header>
      <v-form @submit.prevent="submitC4c" class="px-1">
        <v-text-field
          v-model="c4cContactId"
          variant="solo"
          hide-details
          placeholder="Input c4c contact id here"
        >
          <template v-slot:append>
            <v-btn
              icon
              flat
              :disabled="isC4cContactIdEmpty"
              color="green"
              type="submit"
              class="ma-0 ml-2 px-2"
            >
              <v-icon icon="check"></v-icon>
            </v-btn>
            <v-btn icon flat color="red" @click="reset" class="ma-0 ml-2 px-2">
              <v-icon icon="close"></v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-form>

      <header class="caption text-uppercase">Token</header>
      <v-form @submit.prevent="submitToken" class="px-1">
        <v-text-field
          v-model="token"
          variant="solo"
          hide-details
          placeholder="Input token here"
        >
          <template v-slot:append>
            <v-btn
              icon
              flat
              :disabled="isTokenEmpty"
              color="green"
              type="submit"
              class="ma-0 ml-2 px-2"
            >
              <v-icon icon="check"></v-icon>
            </v-btn>
            <v-btn icon flat color="red" @click="reset" class="ma-0 ml-2 px-2">
              <v-icon icon="close"></v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-form>
    </div>
  </v-menu>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import useAuth0Api from "@/composables/auth0";

export default defineComponent({
  setup() {
    const { getTokenValue, getC4cValue } = useAuth0Api();

    const c4cContactId = ref("");
    const token = ref("");

    onMounted(() => {
      void getToken();
      void getC4c();
    });

    async function getToken() {
      try {
        token.value = await getTokenValue();
      } catch (e) {
        token.value = "";
      }
    }

    async function getC4c() {
      try {
        c4cContactId.value = await getC4cValue();
      } catch (e) {
        c4cContactId.value = "";
      }
    }

    const isC4cContactIdEmpty = computed(() => {
      return c4cContactId.value.trim() === "";
    });

    const isTokenEmpty = computed(() => {
      return token.value.trim() === "";
    });

    const submitC4c = () => {
      if (isC4cContactIdEmpty.value) return;
      localStorage.setItem("c4cContactId", c4cContactId.value);
      location.reload();
    };

    const submitToken = () => {
      if (isTokenEmpty.value) return;
      localStorage.setItem("token", token.value);
      location.reload();
    };

    const reset = () => {
      location.reload();
    };

    return {
      c4cContactId,
      isC4cContactIdEmpty,
      token,
      isTokenEmpty,
      submitC4c,
      submitToken,
      reset,
    };
  },
});
</script>

<template>
  <div>
    <!-- Heading -->
    <v-container fluid>
      <v-sheet color="transparent" :max-width="thresholds.md" class="mx-auto">
        <v-layout>
          <v-row align-content="center" justify="space-between">
            <v-col shrink cols="12" :order-xs1="smAndUp">
              <v-btn
                outline
                :block="xs"
                color="primary"
                class="ma-0"
                exact
                :to="getLocalizedUrl(consts('URL_TICKET_LIST'))"
              >
                <v-icon left>arrow_back</v-icon>
                {{ $t("ticket-detail-back") }}
              </v-btn>
            </v-col>
            <v-col>
              <v-layout>
                <v-row>
                  <v-col shrink>
                    <h1 class="text-h6 text-secondary">
                      {{ $t("ticket-detail-title") }}
                    </h1>
                  </v-col>
                  <v-col shrink>
                    <p class="text-h6 text-grey font-weight-thin ma-0">
                      {{ ticketId }}
                    </p>
                  </v-col>
                </v-row>
              </v-layout>
            </v-col>
          </v-row>
        </v-layout>
      </v-sheet>
    </v-container>
    <!-- Alert -->
    <v-container v-if="alertVisible" fluid class="pt-0">
      <v-sheet color="transparent" :max-width="thresholds.md" class="mx-auto">
        <v-alert
          v-model="alertVisible"
          variant="outlined"
          dismissible
          type="warning"
          class="ma-0"
        >
          Unfortunately, the process of the ticket took longer than expected...
        </v-alert>
      </v-sheet>
    </v-container>
    <!-- Summary -->
    <v-container fluid :class="{ 'pt-0': true, 'px-0': xs }">
      <v-card :max-width="thresholds.md" class="mx-auto">
        <v-progress-linear
          v-show="isFetching"
          indeterminate
          height="2"
          class="ticket-detail-progress"
        />
        <!-- Summary -->
        <v-container fluid grid-list-lg>
          <v-layout>
            <v-row>
              <!-- Subject -->
              <v-col cols="12">
                <p class="ma-0">
                  <span class="d-block text-caption text-grey">
                    {{ $t("prop-subject") }}
                  </span>
                  <span v-if="ticket.Subject" class="d-block text-body-2">
                    {{ ticket.Subject }}
                  </span>
                  <span v-else class="d-block">&nbsp;</span>
                </p>
              </v-col>
              <!-- Description -->
              <v-col cols="12">
                <p class="text-caption text-grey ma-0">
                  {{ $t("prop-description") }}
                </p>
                <div v-if="description">
                  <div
                    v-if="description"
                    :class="{
                      'ticket-detail-description': true,
                      'ticket-detail-description--collapsed':
                        isDescriptionCollapsable && isDescriptionCollapsed,
                      'rm-child-margin': true,
                    }"
                    v-html="description"
                  ></div>
                  <v-btn
                    v-if="isDescriptionCollapsable"
                    color="primary"
                    variant="flat"
                    size="small"
                    class="text-caption"
                    style="min-width: 0; margin: 0 0 0 -8px"
                    @click="isDescriptionCollapsed = !isDescriptionCollapsed"
                  >
                    <template v-if="isDescriptionCollapsed">
                      {{ $t("ticket-detail-read-more") }}
                    </template>
                    <template v-else>
                      {{ $t("ticket-detail-read-less") }}
                    </template>
                  </v-btn>
                </div>
                <p v-else class="ma-0">&nbsp;</p>
              </v-col>
              <!-- Date created -->
              <v-col cols="12" sm="6">
                <p class="ma-0">
                  <span class="d-block text-caption text-grey">
                    {{ $t("prop-date-create") }}
                  </span>
                  <span v-if="ticket.CreatedDate" class="d-block">
                    {{ $d(new Date(ticket.CreatedDate), "datetime") }}
                  </span>
                  <span v-else class="d-block">&nbsp;</span>
                </p>
              </v-col>
              <!-- Last update -->
              <v-col cols="12" sm="6">
                <p class="ma-0">
                  <span class="d-block text-caption text-grey">
                    {{ $t("prop-date-update") }}
                  </span>
                  <span v-if="ticket.LastChangeDate" class="d-block">
                    {{ $d(new Date(ticket.LastChangeDate), "datetime") }}
                  </span>
                  <span v-else class="d-block">&nbsp;</span>
                </p>
              </v-col>
              <!-- Priority -->
              <v-col cols="12" sm="6">
                <p class="ma-0">
                  <span class="d-block text-caption text-grey">
                    {{ $t("prop-priority") }}
                  </span>
                  <span v-if="ticket.PriorityCode" class="d-block">
                    <chip-priority :code="ticket.PriorityCode" />
                  </span>
                  <span v-else class="d-block">&nbsp;</span>
                </p>
              </v-col>
              <!-- Status -->
              <v-col cols="12" sm="6">
                <p class="ma-0">
                  <span class="d-block text-caption text-grey">
                    {{ $t("prop-status") }}
                  </span>
                  <span v-if="ticket.StatusCode" class="d-block">
                    <chip-status :code="ticket.StatusCode" />
                  </span>
                  <span v-else class="d-block">&nbsp;</span>
                </p>
              </v-col>
              <!-- Product -->
              <v-col cols="12" sm="6">
                <p class="ma-0">
                  <span class="d-block text-caption text-grey">
                    {{ $t("prop-product") }}
                  </span>
                  <span v-if="ticket.Product" class="d-block">
                    {{ ticket.Product }}
                  </span>
                  <span v-else class="d-block">&nbsp;</span>
                </p>
              </v-col>
              <!-- Support contact -->
              <v-col cols="12" sm="6">
                <p class="ma-0">
                  <span class="d-block text-caption text-grey">
                    {{ $t("prop-support-contact") }}
                  </span>
                  <span v-if="ticket.SupportContact" class="d-block">
                    {{ ticket.SupportContact }}
                  </span>
                  <span v-else class="d-block">&nbsp;</span>
                </p>
              </v-col>
              <!-- Contract -->
              <v-col cols="12" sm="6">
                <p class="ma-0">
                  <span class="d-block text-caption text-grey">
                    {{ $t("prop-contract") }}
                  </span>
                  <span v-if="ticket.ContractName" class="d-block">
                    {{ ticket.ContractName }}
                  </span>
                  <span v-else class="d-block">&nbsp;</span>
                </p>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </v-card>
    </v-container>
    <!-- Comments & Attachments -->
    <v-container
      fluid
      :class="{
        'pt-0': true,
        'pb-0': xs,
        'px-0': xs,
      }"
    >
      <v-card :max-width="thresholds.md" class="mx-auto">
        <v-progress-linear
          v-show="isFetching"
          indeterminate
          height="2"
          class="ticket-detail-progress"
        />
        <!-- Tabs -->
        <v-tabs v-model="tab" grow slider-color="secondary">
          <v-tab v-for="item in tabItems" :key="item" :value="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item :value="tabItems[0]">
            <v-container fluid>
              <!-- Add comment -->
              <v-layout>
                <v-row>
                  <v-col
                    v-if="!xs"
                    :style="{
                      width: `${xs ? 48 : 144}px`,
                    }"
                  />
                  <v-col cols="9">
                    <v-sheet :color="newCommentColor" style="padding: 1px">
                      <div class="white">
                        <v-textarea
                          v-model="newComment"
                          variant="solo"
                          auto-grow
                          :hide-details="!isPostingCommentError"
                          :error-messages="
                            isPostingCommentError
                              ? [$t('ticket-detail-comment-post-error')]
                              : []
                          "
                          rows="1"
                          :placeholder="$t('ticket-detail-comment-placeholder')"
                          :color="newCommentColor"
                        ></v-textarea>
                      </div>
                    </v-sheet>
                  </v-col>
                  <v-col>
                    <v-tooltip location="top" :color="newCommentColor">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          @click="addComment"
                          icon="send"
                          variant="outlined"
                          :loading="isPostingComment"
                          v-bind="props"
                          :color="newCommentColor"
                          class="ma-0 elevation-4"
                        >
                        </v-btn>
                      </template>
                      <span class="text-caption">{{
                        $t("ticket-detail-comment-submit")
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-layout>
              <!-- Comment list -->
              <v-layout
                v-for="(comment, i) in comments"
                :key="`comment-${i}`"
                :row="!comment.FromExfo"
                :reverse="!comment.FromExfo"
              >
                <v-row>
                  <v-col
                    :style="{
                      visibility: comment.SameAsPrevious ? 'hidden' : 'visible',
                    }"
                  >
                    <v-avatar
                      :size="xs ? 32 : 56"
                      :color="comment.FromExfo ? 'secondary' : 'light-green'"
                    >
                      <v-img
                        v-if="comment.FromExfo"
                        :src="require('@/assets/exfo-avatar.png')"
                        alt=""
                      />
                      <v-icon v-else :size="xs ? 24 : 40" color="white"
                        >person</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <v-card
                      flat
                      :color="
                        comment.FromExfo
                          ? 'blue-lighten-5'
                          : 'light-green-lighten-5'
                      "
                    >
                      <v-card-text
                        :class="
                          comment.FromExfo
                            ? 'rm-child-margin text-secondary'
                            : 'rm-child-margin text-light-green grey-darken-4'
                        "
                      >
                        <p
                          :class="{ 'text-caption': xs }"
                          v-html="comment.Text"
                        ></p>
                        <p
                          v-if="comment.AuthorName || comment.CreatedDate"
                          :class="{
                            'text-caption': true,
                            'text-xs-right': !comment.FromExfo,
                          }"
                          style="opacity: 0.6"
                        >
                          <span v-if="comment.AuthorName">
                            {{ comment.AuthorName }}
                          </span>
                          <span
                            v-if="comment.AuthorName && comment.CreatedDate"
                          >
                            -
                          </span>
                          <span v-if="comment.CreatedDate">
                            {{ $d(new Date(comment.CreatedDate), "datetime") }}
                          </span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    grow
                    :style="{
                      width: `${xs ? 48 : 144}px`,
                    }"
                  />
                </v-row>
              </v-layout>
            </v-container>
          </v-window-item>
          <v-window-item :value="tabItems[1]">
            <!-- Attachments listing -->
            <div
              v-if="attachments.length > 0"
              :class="{
                'px-2': mdAndUp,
                'pt-3': smAndDown,
                'pt-4': mdAndUp,
              }"
            >
              <v-list lines="two" class="py-0">
                <v-list-item
                  v-for="(attachment, index) in attachments"
                  :key="`attachment-${index}`"
                  :href="attachment.DocumentLink"
                  :title="attachment.Name"
                >
                  <v-list-item-subtitle>
                    {{ $t("ticket-detail-attachment-date-create") }}
                    {{ $d(new Date(attachment.CreatedDate), "datetime") }}
                  </v-list-item-subtitle>

                  <template v-slot:append>
                    <v-icon color="primary">attach_file</v-icon>
                  </template>
                </v-list-item>
              </v-list>
            </div>
            <!-- Attachment posting error -->
            <v-container v-show="isPostingAttachmentError" fluid class="pb-0">
              <v-alert
                v-model="isPostingAttachmentError"
                variant="outlined"
                dismissible
                type="error"
                class="ma-0"
              >
                {{ $t("ticket-detail-attachment-post-error") }}
              </v-alert>
            </v-container>
            <!-- Add attachments -->
            <v-container fluid>
              <v-file-input
                clearable
                label="Attachments"
                multiple
                v-model="newAttachments"
                :disabled="isPostingAttachment"
                :loading="isPostingAttachment"
                @update:model-value="addAttachment"
              ></v-file-input>
            </v-container>
          </v-window-item>
        </v-window>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { Consts } from "@/helpers/consts";
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import fileshelper from "@/helpers/files";
import { useStore } from "vuex";
import ChipPriority from "@/components/chip/priority";
import ChipStatus from "@/components/chip/status";
import ticketsService from "@/services/tickets";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import useLocalizedUrl from "@/composables/localizedUrl";
import useConsts from "@/composables/consts";
import useAuth0Api from "@/composables/auth0";

const FAKE_COMMENTS = (function () {
  let arr = [];
  let mkComment = (obj) => {
    arr.push({
      ServiceRequestID: "",
      TypeCode: "",
      TypeText: "",
      Text: "",
      CreatedDate: "",
      AuthorName: "",
      ...obj,
    });
  };
  mkComment({
    TypeCode: Consts.COMMENT_CODE_TO_CUSTOMER,
    Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt ullamcorper quam, a pellentesque nulla elementum sed. Donec a mi nunc.",
    CreatedDate: "2018-09-01T14:03:28.144Z",
    AuthorName: "Exfo Name",
  });
  mkComment({
    TypeCode: Consts.COMMENT_CODE_TO_CUSTOMER,
    Text: "Aliquam erat volutpat.",
    CreatedDate: "2018-09-01T14:03:29.144Z",
    AuthorName: "Exfo Name",
  });
  mkComment({
    TypeCode: Consts.COMMENT_CODE_DESCRIPTION,
    Text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    CreatedDate: "2018-09-01T14:02:28.144Z",
    AuthorName: "Client Name",
  });
  mkComment({
    TypeCode: Consts.COMMENT_CODE_FROM_CUSTOMER,
    Text: "Curabitur suscipit tellus leo.",
    CreatedDate: "2018-09-10T14:03:28.144Z",
    AuthorName: "Client Name",
  });
  return arr;
})();

function fake_attachment() {
  let arr = [];
  let mkAttachment = (obj) => {
    arr.push({
      Name: "",
      DocumentLink: "#",
      CreatedDate: "",
      ...obj,
    });
  };
  mkAttachment({
    Name: "Lorem ipsum dolor sit amet",
    CreatedDate: "2018-09-01T14:03:28.144Z",
  });
  mkAttachment({
    Name: "Consectetur adipiscing elit",
    CreatedDate: "2018-09-09T14:09:15.144Z",
  });
  mkAttachment({
    Name: "Nulla tincidunt ullamcorper quam",
    CreatedDate: "2018-10-03T14:05:28.144Z",
  });
  return arr;
}

export default defineComponent({
  components: {
    ChipPriority,
    ChipStatus,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const store = useStore();
    const { thresholds, xs, smAndUp, mdAndUp, smAndDown } = useDisplay();
    const { getLocalizedUrl } = useLocalizedUrl();
    const { consts } = useConsts();
    const { getC4cValue, getTokenValue } = useAuth0Api();

    const ticketId = ref(null);
    const alertVisible = ref(false);
    const isFetching = ref(false);
    const isPostingComment = ref(false);
    const isPostingCommentError = ref(false);
    const isPostingAttachment = ref(false);
    const isPostingAttachmentError = ref(false);
    const tab = ref(null);
    const tabItems = [t("prop-comments"), t("prop-attachments")];
    const newComment = ref("");
    const isDescriptionCollapsed = ref(false);
    const newAttachments = ref([]);

    onMounted(() => {
      isFetching.value = true;
      ticketId.value = route.params.id;
      getC4cValue().then((c4c) => {
        getTokenValue()
            .then((token) => readTicket(token, ticketId.value, c4c))
            .then((token) => (isFetching.value = false))
            .catch(() => (isFetching.value = false));
      })

    });

    const tickets = computed(() => Consts.STATE_TICKETS_LIST(store.state));

    const ticket = computed(() => {
      let ticket = tickets.value.filter((t) => t.TicketID === ticketId.value);
      return ticket[0] ? ticket[0] : {};
    });

    const comments = computed(() => {
      let comments = ticket.value.Comments || [];
      comments = comments.filter(
        (x) => x.TypeCode !== Consts.COMMENT_CODE_DESCRIPTION
      );
      comments.sort((a, b) => {
        if (new Date(a.CreatedDate) > new Date(b.CreatedDate)) {
          return -1;
        }
        if (new Date(a.CreatedDate) < new Date(b.CreatedDate)) {
          return 1;
        }
        return 0;
      });
      let PrevFromExfo;
      comments.forEach((x) => {
        x.FromExfo = x.TypeCode === Consts.COMMENT_CODE_TO_CUSTOMER;
        x.SameAsPrevious = PrevFromExfo === x.FromExfo;
        PrevFromExfo = x.FromExfo;
        x.Text = x.Text.replaceAll("\n", "<br/>");
      });
      return comments;
    });

    const description = computed(() => {
      let comments = ticket.value.Comments || [];
      comments = comments.filter(
        (x) => x.TypeCode === Consts.COMMENT_CODE_DESCRIPTION
      );
      return comments[0] && comments[0].Text
        ? comments[0].Text.replaceAll("\n", "<br/>")
        : "";
    });

    const isDescriptionCollapsable = computed(() => {
      return description.value.length > 500;
    });

    const newCommentValid = computed(() => {
      return newComment.value;
    });

    const newCommentColor = computed(() => {
      return newCommentValid.value
        ? isPostingCommentError.value
          ? "red"
          : "light-green"
        : "grey-lighten-1";
    });

    const attachments = computed(() => {
      //return fake_attachment();
      let attachments = ticket.value.Attachments || [];
      attachments.sort((a, b) => {
        if (new Date(a.CreatedDate) < new Date(b.CreatedDate)) {
          return -1;
        }
        if (new Date(a.CreatedDate) > new Date(b.CreatedDate)) {
          return 1;
        }
        return 0;
      });
      return attachments;
    });

    function readTicket(token, ticketId, c4c) {
      return store.dispatch(Consts.ACTION_TICKETS_READ_ONE, {
        token,
        ticketId,
        c4c,
      });
    }

    function addComment() {
      if (!newCommentValid.value || isPostingComment.value) {
        return;
      }
      isPostingComment.value = true;
      isPostingCommentError.value = false;
      getC4cValue().then((c4c) => {
        getTokenValue()
          // post comment
          .then((token) => {
            return ticketsService.addComment(
              token,
              ticketId.value,
              newComment.value,
              c4c
            );
          })
          // stop anim comment
          .then((token) => {
            newComment.value = "";
            isPostingComment.value = false;
            return Promise.resolve(token);
          })
          // anim load ticket
          .then((token) => {
            isFetching.value = true;
            return Promise.resolve(token);
          })
          // read ticket
          .then((token) => readTicket(token, ticketId.value, c4c))
          // stop anim ticket
          .then((token) => {
            isFetching.value = false;
            return Promise.resolve(token);
          })
          // stop anim ticket && comment
          .catch((e) => {
            isFetching.value = false;
            isPostingComment.value = false;
            isPostingCommentError.value = true;
            console.error(e);
          });
      });
    }

    function addAttachment() {
      if (isPostingAttachment.value || !newAttachments.value) {
        return;
      }

      isPostingAttachment.value = true;
      isPostingAttachmentError.value = false;
      getC4cValue().then((c4c) => {
        getTokenValue()
          .then(
            (token) =>
              new Promise((resolve, reject) => {
                let files = [];
                let requests = newAttachments.value.map((item) => {
                  return fileshelper
                    .getBase64(item)
                    .then((data) =>
                      files.push({ name: item.name, binary: data })
                    );
                });
                Promise.all(requests).then(() => {
                  ticketsService
                    .addAttachment(token, ticketId.value, c4c, files)
                    .then((token) => {
                      resolve(token);
                    })
                    .catch(() => {
                      reject();
                    });
                });
              })
          )
          // remove uploaded files
          .then((token) => {
            newAttachments.value = [];
            return Promise.resolve(token);
          })
          // stop anim attachment
          .then((token) => {
            isPostingAttachment.value = false;
            return Promise.resolve(token);
          })
          // anim load ticket
          .then((token) => {
            isFetching.value = true;
            return Promise.resolve(token);
          })
          // read ticket
          .then((token) => readTicket(token, ticketId.value, c4c))
          // stop anim ticket
          .then((token) => {
            isFetching.value = false;
            return Promise.resolve(token);
          })
          // stop anim ticket && comment
          .catch((e) => {
            isFetching.value = false;
            isPostingAttachment.value = false;
            isPostingAttachmentError.value = true;
            console.error(e);
          });
      });
    }

    watch(newComment, () => {
      isPostingCommentError.value = false;
    });

    return {
      thresholds,
      xs,
      smAndUp,
      mdAndUp,
      smAndDown,
      ticketId,
      alertVisible,
      isFetching,
      isPostingComment,
      isPostingCommentError,
      isPostingAttachment,
      isPostingAttachmentError,
      tab,
      tabItems,
      newComment,
      isDescriptionCollapsed,
      tickets,
      ticket,
      comments,
      description,
      isDescriptionCollapsable,
      newCommentValid,
      newCommentColor,
      attachments,
      newAttachments,
      addComment,
      addAttachment,
      getLocalizedUrl,
      consts,
    };
  },
});
</script>

<style lang="scss" scoped>
.ticket-detail-progress {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ticket-detail-description p,
.ticket-detail-description ul,
.ticket-detail-description ol {
  margin-bottom: 24px;
}
.ticket-detail-description--collapsed {
  max-height: 72px;
  overflow: hidden;
}
</style>

<template>
  <div>
    <!-- Heading -->
    <v-container fluid>
      <v-sheet color="transparent" :max-width="thresholds.md" class="mx-auto">
        <v-row align-content="center" justify="space-between">
          <v-col cols="12" sm="6">
            <v-btn
              variant="outlined"
              :block="xs"
              color="primary"
              class="ma-0"
              exact
              :to="getLocalizedUrl(consts('URL_TICKET_LIST'))"
            >
              <v-icon left>arrow_back</v-icon>
              {{ $t("ticket-add-back") }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <h1 class="text-h6 text-secondary">
              {{ $t("ticket-add-title") }}
            </h1>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
    <!-- Alert -->
    <v-container v-if="isErrorVisible" fluid class="pt-0">
      <v-sheet color="transparent" :max-width="thresholds.md" class="mx-auto">
        <v-alert
          v-model="isErrorVisible"
          variant="outlined"
          closable
          color="error"
          class="ma-0"
        >
          {{ $t("ticket-add-post-error") }}
        </v-alert>
      </v-sheet>
    </v-container>
    <!-- Form -->
    <v-container fluid :class="{ 'pt-0': true, 'px-0': xs }">
      <v-card :max-width="thresholds.md" class="mx-auto">
        <v-progress-linear
          v-show="isPosting"
          indeterminate
          height="2"
          class="ticket-add-progress"
        />
        <v-container fluid>
          <v-form v-model="valid" @submit.prevent="submit" ref="form">
            <v-row wrap>
              <!-- Fields -->
              <v-col cols="12" sm="6">
                <v-row>
                  <!-- Subject -->
                  <v-col cols="12">
                    <p class="text-body-2 ma-0">
                      {{ $t("prop-subject") }}
                      <span class="text-red">*</span>
                    </p>
                    <v-text-field
                      v-model="subject"
                      :rules="[rulesSubject]"
                      class="ma-0 pt-0"
                    />
                  </v-col>
                  <!-- Description (Comment) -->
                  <v-col cols="12">
                    <p class="text-body-2 ma-0">
                      {{ $t("prop-description") }}
                      <span class="text-red">*</span>
                    </p>
                    <v-textarea
                      v-model="comment"
                      :rules="[rulesComment]"
                      auto-grow
                      rows="3"
                      class="ma-0 pt-0"
                    />
                  </v-col>
                  <!-- Maintenance contract -->
                  <v-col cols="12" v-if="contractsItems.length">
                    <p class="text-body-2 ma-0">
                      {{ $t("prop-contract") }}
                    </p>
                    <v-autocomplete
                      v-model="contractId"
                      :items="contractsItems"
                      item-value="value"
                      item-title="text"
                      :loading="isFetchingContracts"
                      class="ma-0 pt-0"
                    />
                  </v-col>
                  <!-- Product -->
                  <v-col cols="12">
                    <p class="text-body-2 ma-0">
                      {{ $t("prop-product") }}
                      <span class="text-red">*</span>
                    </p>
                    <v-autocomplete
                      v-model="productId"
                      :items="productsItems"
                      item-value="value"
                      item-title="text"
                      :loading="isFetchingProducts"
                      :rules="[rulesProduct]"
                      class="ma-0 pt-0"
                    />
                  </v-col>
                  <!-- Priority -->
                  <v-col cols="12">
                    <p class="text-body-2 ma-0">
                      {{ $t("prop-priority") }}
                      <span class="text-red">*</span>
                    </p>
                    <v-select
                      v-model="priorityCode"
                      :items="prioritiesItems"
                      item-value="value"
                      item-title="text"
                      :rules="[rulesPriority]"
                      class="ma-0 pa-0"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <!-- Attachments -->
                <v-layout full-height>
                  <v-col class="pa-0">
                    <p class="text-body-2">{{ $t("prop-attachments") }}</p>
                    <v-file-input
                      clearable
                      label="Attachments"
                      multiple
                      v-model="attachments"
                      :disabled="isPosting"
                      :loading="isPosting"
                    ></v-file-input>
                  </v-col>
                </v-layout>
              </v-col>
              <!-- Submit -->
              <v-col cols="12" class="text-right">
                <v-btn
                  large
                  color="primary"
                  class="ma-0"
                  :disabled="isPosting"
                  :loading="isPosting"
                  @click="submit"
                >
                  {{ $t("ticket-add-submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { Consts } from "@/helpers/consts";
import fileshelper from "@/helpers/files";
import rules from "@/helpers/rules";
import tickets from "@/services/tickets";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useLocalizedUrl from "@/composables/localizedUrl";
import useConsts from "@/composables/consts";
import { useDisplay } from "vuetify";
import useAuth0Api from "@/composables/auth0";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { thresholds, xs, smAndUp } = useDisplay();
    const { getLocalizedUrl } = useLocalizedUrl();
    const { consts } = useConsts();
    const { getC4cValue, getTokenValue } = useAuth0Api();

    const form = ref(null);
    const isFetchingProducts = ref(false);
    const isFetchingContracts = ref(false);
    const isPosting = ref(false);
    const isErrorVisible = ref(false);
    const valid = ref(null);
    const subject = ref(null);
    const comment = ref(null);
    const contractId = ref(null);
    const productId = ref(null);
    const priorityCode = ref(null);
    const attachments = ref([]);

    const rulesSubject = (val) =>
      rules.required(val) || t("ticket-add-subject-required");
    const rulesComment = (val) =>
      rules.required(val) || t("ticket-add-comment-required");
    const rulesProduct = (val) =>
      rules.required(val) || t("ticket-add-product-required");
    const rulesPriority = (val) =>
      Consts.PRIORITY_CODE.includes(val) || t("ticket-add-priority-required");

    const products = computed(() => Consts.STATE_PRODUCTS_LIST(store.state));
    const contracts = computed(() => Consts.STATE_CONTRACTS_LIST(store.state));

    const contractsItems = computed(() => {
      let items = [];
      contracts.value.forEach((x) =>
        items.push({ text: x.Name, value: x.ContractID })
      );
      return items;
    });

    const productsItems = computed(() => {
      let items = [];
      products.value.forEach((x) =>
        items.push({ text: x.ProductText, value: x.ProductID })
      );
      return items;
    });

    const prioritiesItems = computed(() => {
      let items = [];
      Consts.PRIORITY_CODE.forEach((x) =>
        items.push({ text: t(`priority-code-${x}`), value: x })
      );
      return items;
    });

    const readAllProducts = (token) =>
      store.dispatch(Consts.ACTION_PRODUCTS_READ_ALL, token);

    const readAllContracts = (token, c4c) => {
      return store.dispatch(Consts.ACTION_CONTRACTS_READ_ALL, { token, c4c });
    };

    onMounted(() => {
      isFetchingProducts.value = true;
      isFetchingContracts.value = true;
      getC4cValue().then((c4c) => {
        getTokenValue()
          .then((token) => {
            readAllProducts({ token }).then(
              (token) => (isFetchingProducts.value = false)
            );
            readAllContracts(token, c4c).then(
              (token) => (isFetchingContracts.value = false)
            );
          })
          .catch(() => {
            isFetchingProducts.value = false;
            isFetchingContracts.value = false;
          });
      });
    });

    const submit = () => {
      form.value.validate();
      if (!valid.value) {
        return;
      }
      isPosting.value = true;
      isErrorVisible.value = false;
      let newTicket = {
        subject: subject.value,
        product: productId.value,
        contractId: contractId.value,
        priorityCode: priorityCode.value,
        comments: [{ text: comment.value }],
        attachments: [],
      };
      getC4cValue().then((c4c) => {
        getTokenValue()
          .then(
            (token) =>
              new Promise((resolve, reject) => {
                let requests = attachments.value.map((item) => {
                  return fileshelper.getBase64(item).then((data) =>
                    newTicket.attachments.push({
                      name: item.name,
                      binary: data,
                    })
                  );
                });

                Promise.all(requests)
                  .then(() => {
                    resolve(token);
                  })
                  .catch(() => {
                    reject();
                  });
              })
          )
          .then(
            (token) =>
              new Promise((resolve, reject) => {
                tickets
                  .createTicket(token, newTicket, c4c)
                  .then((success) => {
                    isPosting.value = false;
                    if (success) {
                      isErrorVisible.value = false;
                      router.push(
                        getLocalizedUrl(Consts.URL_TICKET_CONFIRM).replace(
                          ":id",
                          success.TicketID
                        )
                      );
                    } else {
                      isErrorVisible.value = true;
                    }
                    resolve(token);
                  })
                  .catch((e) => reject(e));
              })
          )
          .catch((e) => {
            console.error(e);
            isPosting.value = false;
            isErrorVisible.value = true;
          });
      });
    };

    return {
      form,
      smAndUp,
      xs,
      thresholds,
      isFetchingProducts,
      isFetchingContracts,
      isPosting,
      isErrorVisible,
      valid,
      subject,
      comment,
      contractId,
      productId,
      priorityCode,
      products,
      contractsItems,
      productsItems,
      prioritiesItems,
      attachments,
      rulesSubject,
      rulesComment,
      rulesProduct,
      rulesPriority,
      submit,
      consts,
      getLocalizedUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
.ticket-add-progress {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>

const state = {
  list: [
    // {
    //   id: 'a',
    //   text: 'Lorem ipsum A',
    //   timeout: 5000
    // },
    // {
    //   id: 'b',
    //   text: 'Lorem ipsum B',
    //   timeout: 5000
    // }
  ]
}

const mutations = {
  addItem (state, options) {
    state.list = state.list.filter(x => x.id !== options.id)
    state.list.push(options)
  },
  deleteItem (state, id) {
    state.list = state.list.filter(x => x.id !== id)
  }
}

export default {
  namespaced: true,
  state,
  mutations
}

const state = {
  search: null,
  openOnly: true,
  dateCreateFrom: null,
  dateCreateTo: null,
  dateUpdateFrom: null,
  dateUpdateTo: null,
  product: null,
  priorityCode: null,
  statusCode: null,
  supportContact: null,
  sortBy: 'TicketID',
  sortType: 'desc',
  pagination: [{
    key: 'TicketID',
    order: 'desc',
  }]
}

const mutations = {
  search (state, search) {
    state.search = search
  },
  openOnly (state, openOnly) {
    state.openOnly = openOnly
  },
  dateCreateFrom (state, dateCreateFrom) {
    state.dateCreateFrom = dateCreateFrom
  },
  dateCreateTo (state, dateCreateTo) {
    state.dateCreateTo = dateCreateTo
  },
  dateUpdateFrom (state, dateUpdateFrom) {
    state.dateUpdateFrom = dateUpdateFrom
  },
  dateUpdateTo (state, dateUpdateTo) {
    state.dateUpdateTo = dateUpdateTo
  },
  product (state, product) {
    state.product = product
  },
  priorityCode (state, priorityCode) {
    state.priorityCode = priorityCode
  },
  statusCode (state, statusCode) {
    state.statusCode = statusCode
  },
  supportContact (state, supportContact) {
    state.supportContact = supportContact
  },
  pagination (state, pagination) {
    state.pagination = pagination
  },
  sortBy (state, sortBy) {
    state.sortBy = sortBy
  },
  sortType (state, sortType) {
    state.sortType = sortType
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
